
import action from "@/actions.js"
import qs from 'qs'
export default {

    data(){
        return {
			upload_url:action.uploadpic,
			MainImgList:[],
			LegalPersonFileList:[],
			CertificationFileList:[],
			AuthorizationFileList:[],
			ContractFileList:[],
            agentinfo:{
				ID:'',
                AgentName:'',
				LegalPersonIDS:[],
				Authorization:[],
				Contract:[],
				Certification:[],
				AgentCode:'',
				Address:'',
				Mobile:'',
				Email:'',
				Scale:'',
				Turnover:'',
				Brief:'',
				LegalPerson:'',
				ManagerID:'',
				AuthorizationCode:'',
				StartDTime:'',
				ExpireDTime:'',
            },
			rule:{
				AgentName:[{
					required:'true',
					message:"请填写商户名称",
					trigger:'blur'
				}],
				ManagerID:[{
					required:'true',
					message:"请选择客户代表",
					trigger:'blur'
				}]
			},
			curimglist:'',
			dialogVisible:false,
			dialogImageUrl: '',
        }
    },

    beforeCreate() {
		this.PAGELANG = this.LANG.pages.AddAgent;
	},
	methods:{
		onSubmit(){
			console.log(this.agentinfo);
			this.$refs['agentinfo'].validate((valid)=>{
				if(valid){
					this.axios.post(action.saveagent,qs.stringify(this.agentinfo),{
						headers:{
							"content-type":"application/x-www-form-urlencoded"
						}
					}).then((response)=>{
						console.log(response.data);
						if(response.data == 'OK'){
							this.$message.success("操作成功");
						}else if(response.data == 'REPEAT'){
							this.$message.error("已存在该商户姓名或商户代码");
							return false;
						}
					});
				}else{
					return false;
				}
			});
			
		},
		Setuploadlist(name){
			this.curimglist = name;
		},
		UploadExceed(file,filelist){
            this.$message.warning(`当前限制选择 10 个图片,本次选择了 ${file.length} 个文件,共选择了 ${file.length + filelist.length} 个文件`);
        },
        UploadSuccess(response,file,filelist){
            console.log(response);
            if(response.msg == 'OK'){
				if(this.curimglist == 'legalperson')
					this.agentinfo.LegalPersonIDS = [];
				else if(this.curimglist == 'certification')
					this.agentinfo.Certification = [];
				else if(this.curimglist == 'authorization')
					this.agentinfo.Authorization = [];
				else if(this.curimglist == 'contract')
					this.agentinfo.Contract = [];
                for(var i = 0;i<filelist.length;i++){
                    var uid = filelist[i]['uid'];
                    var name = filelist[i]['name'];
                    var url = '';
                    if(filelist[i]['response']){
                        var upload_response = filelist[i]['response'];
                        url = upload_response.url;
                    }else{
                        url = filelist[i]['url'];
                    }
                    var obj = new Object();
                    obj.uid = uid;
                    obj.name = name;
                    obj.url = url;
					console.log("curimglist--" + this.curimglist);
					if(this.curimglist == 'legalperson'){
						this.agentinfo.LegalPersonIDS.push(obj);
						console.log("legalperson");
						console.log(this.agentinfo.LegalPersonIDS);
					}
					else if(this.curimglist == 'certification'){
						this.agentinfo.Certification.push(obj);
						console.log(this.agentinfo.Certification);
					}
					else if(this.curimglist == 'authorization'){
						this.agentinfo.Authorization.push(obj);
						console.log(this.agentinfo.Authorization);
					}
					else if(this.curimglist == 'contract'){
						this.agentinfo.Contract.push(obj);
						console.log(this.agentinfo.Contract);
					}
                }
            }
            
        },
        RemovePic(file,filelist){
            console.log(file);
            console.log(filelist);
			if(this.curimglist == 'legalperson')
				this.agentinfo.LegalPersonIDS = [];
			else if(this.curimglist == 'certification')
				this.agentinfo.Certification = [];
			else if(this.curimglist == 'authorization')
				this.agentinfo.Authorization = [];
			else if(this.curimglist == 'contract')
				this.agentinfo.Contract = [];
            for(var i = 0;i<filelist.length;i++){
                var uid = filelist[i]['uid'];
                var name = filelist[i]['name'];
                var url = filelist[i]['url'];
                var obj = new Object();
                obj.uid = uid;
                obj.name = name;
                obj.url = url;
                if(this.curimglist == 'legalperson')
                    this.agentinfo.LegalPersonIDS.push(obj);
				else if(this.curimglist == 'certification')
					this.agentinfo.Certification.push(obj);
				else if(this.curimglist == 'authorization')
					this.agentinfo.Authorization.push(obj);
				else if(this.curimglist == 'contract')
					this.agentinfo.Contract.push(obj);
            }
        },
        CheckFile(file){
			
            console.log(file);
            if(file.type != "image/jpg" && file.type !="image/png" && file.type != "image/jpeg"){
                this.$message.warning('当前选择文件中包含不支持上传的类型');
                return false;
            }
			if((file.size*1)/1024 > 2048){
				this.$message.error("当前上传图片尺寸过大,建议图片大小在2MB内");
				return false;
			}
        },
        UploadError(err){
            console.log(err);
            this.$message.error(err);
        },
        Preview(file) {
            console.log(file);
            if(file.response){
                if(file.response.msg == 'OK'){
                    this.dialogImageUrl = file.response.url;
                    console.log(this.dialogImageUrl);
                }
            }
            else{
                this.dialogImageUrl = file.url;
            }
            
            this.dialogVisible = true;
        },
		GoBack(){
			this.$router.push({path:'/customers/agentlist',query:{curpage:this.curpage}});
		}

	},
	created:function(){
		let id = this.$route.query.id;
		let curpage = this.$route.query.curpage;
		if(curpage){
			this.curpage = curpage;
		}
		if(id){
			this.axios.get(action.getagent,{
				headers:{
					"content-type":"application/json"
				},
				params:{
					id:id
				}
			}).then((response)=>{
				console.log(response.data);
				this.agentinfo = response.data;
				if(response.data.LegalPersonIDs)
					this.LegalPersonFileList = response.data.LegalPersonIDs;
				if(response.data.Certification)
					this.CertificationFileList = response.data.Certification;
				if(response.data.Authorization)
					this.AuthorizationFileList = response.data.Authorization;
				if(response.data.Contract)
					this.ContractFileList = response.data.Contract;
				
			});
		}
	}
}
